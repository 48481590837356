/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.style-post > ol {
  @apply list-decimal;
}

.style-post > ul {
  @apply list-disc pl-4;
}

.style-post > ul > li,
.style-post > ol > li {
  @apply pb-4 mt-2;
}

.style-post > p {
  @apply pb-4 mt-2;
}
